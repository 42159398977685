// German
export default{
    form: {
        startSurveyButton: "Los geht's",
        nextButton: "Weiter",
        submitAnswerButton: "Antwort senden",
        continueButton: "Weiter",
        finishButton: "Fertig",
        headerPercentageCompletion: 'abgeschlossen',
        headerQuestionsCompleted: 'Fragen',
        headerDefaultErrorMessage: 'Ups. Etwas ging schief.',
        brandingPoweredBy: 'Bereitgestellt von',
        brandingPromoWhySurvey: 'Warum Umfrage, wenn Sie <b>{product}</b> nutzen können?',
        brandingPromoGetStarted: 'Jetzt loslegen!',
        submissionPending: "Voiceform wird gesendet. Bitte schließen Sie das Fenster nicht.",
        submissionCanCloseWindow: "Ihr Voiceform wurde erfolgreich gesendet. Sie können das Fenster jetzt schließen 👍",
        mediaAttachmentOverlayCloseButton: "Schließen",
        expiredFormHeader: "Danke für Ihren Besuch!",
        expiredFormMessage: "Diese Umfrage ist abgelaufen. Bitte informieren Sie den Ersteller.",
        notPublishedFormHeader: "Voiceform nicht veröffentlicht!",
        notPublishedFormMessage: "Diese Umfrage wurde noch nicht veröffentlicht. Bitte informieren Sie den Ersteller.",
        notPublishedFormRetryButton: "Erneut versuchen",
        errorFormHeader: "Oops!",
        errorFormMessage: "Es ist etwas schiefgelaufen. Bitte versuchen Sie es später erneut.",
        errorFormRetryButton: "Erneut versuchen",
        emptyFormHeader: "Danke für Ihren Besuch!",
        emptyFormMessage: "Oops! Dieses Formular ist leer. Bitte informieren Sie den Ersteller über das Problem. Vielen Dank!",
        restoreFormHeader: "Ein laufendes Formular gefunden!",
        restoreFormMessage: "Möchten Sie dort weitermachen, wo Sie aufgehört haben?",
        restoreFormButton: "Formular fortsetzen",
        restoreFormButtonNew: "Neu beginnen",
        audioPermissionHeader: "Möchten Sie mit der Stimme antworten?",
        audioPermissionMessage: "Sprechen macht das Antworten schnell und einfach.",
        audioPermissionAcceptButton: "Ja, gerne",
        audioPermissionDenyButton: "Nein, danke",
        audioPermissionErrorHeader: "Mikrofon nicht verfügbar",
        audioPermissionErrorMessage: "Überprüfen Sie die Berechtigungen des Browsers.",
        audioPermissionErrorButton: "Okay",
        audioRecorderFailedUpload: "Fehler beim Hochladen der Aufnahme",
        silenceDetectorError: "Wir hören Sie nicht. Überprüfen Sie das Mikrofon.",
        audioRecorderButtonRecord: "Aufnehmen",
        audioRecorderButtonRecordMore: "Mehr aufnehmen",
        voiceResponseSelectionMessage: "Wählen Sie Ihre Antwortmethode...",
        voiceResponseSelectionOrDivider: "oder",
        voiceResponseTextInputPlaceholder: "Antwort eingeben",
        voiceResponseTextInputCharacterCounterMinMessage: "Bitte mindestens {min} Zeichen eingeben",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} Zeichen (mindestens {min})",
        voiceResponseTextInputCharacterValidationMessage: "{count} Zeichen (mindestens {min})",
        checkboxValidationTooFew: "Mindestens {min} Option(en) auswählen",
        checkboxValidationTooMany: "Maximal {max} Option(en) auswählen",
        checkboxNoneOfTheAboveOption: "Keine der oben genannten",
        checkboxInvalidAnswer: "Ungültige Antwort.",
        datePickerPlaceholder: "Datum auswählen",
        emailLabel: "E-Mail",
        emailInvalid: "Ungültige E-Mail.",
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Video aufnehmen",
        fileUploadPluginScreenCast: "Bildschirm teilen",
        fileUploadVideoImportFilesDefault: 'Video aufnehmen oder hochladen:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Option für Videoaufzeichnung wählen:',
        fileUploadVideoImportFilesNoCamera: 'Aufnahmetaste für Bildschirmvideo drücken',
        fileUploadVideoImportFilesNoScreenCast: 'Aufnahmetaste für Video drücken',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Keine Optionen für Videoaufnahme',
        matrixValidationMessage: "Die Antwort ist gültig.",
        matrixRow: "Reihe",
        matrixColumn: "Spalte",
        matrixRowRequired: "Reihe {rowTitle} ist erforderlich.",
        matrixRadioGroup: "Radiogruppe",
        matrixCheckboxGroup: "Checkbox-Gruppe",
        matrixGroupRequired: "{type} {groupTitle} für Reihe {rowTitle} erforderlich.",
        matrixColumnRequired: "Spalte {columnTitle} für Reihe {rowTitle} erforderlich.",
        matrixColumnInvalid: "Spalte {columnTitle} in Reihe {rowTitle} ungültig.",
        matrixRequired: "Erforderlich.",
        matrixNumericMustBeNumber: "Muss eine Zahl sein.",
        matrixNumericMustBeGreaterThenMin: "Muss größer als {min} sein.",
        matrixNumericMustBeLessThenMax: "Muss kleiner als {max} sein.",
        matrixNumericMustBeInteger: "Muss eine Ganzzahl sein.",
        matrixNumericInvalidNumber: "Ungültige Zahl.",
        nameLabel: "Name",
        nameInvalid: "Vor- und Nachname angeben.",
        npsNotLikely: "Überhaupt nicht wahrscheinlich",
        npsExtremelyLikely: "Sehr wahrscheinlich",
        numericInputRequired: "Erforderlich.",
        numericInputMustBeNumber: "Muss eine Zahl sein.",
        numericInputMustBeGreaterThenMin: "Muss größer als {min} sein.",
        numericInputMustBeLessThenMax: "Muss kleiner als {max} sein.",
        numericInputMustBeInteger: "Muss eine Ganzzahl sein.",
        numericInputInvalidNumber: "Ungültige Zahl.",
        numericInputPlaceholder: "Zahl eingeben",
        phoneInvalid: "Ungültige Telefonnummer.",
        phoneCountrySelectorLabel: 'Ländervorwahl',
        phoneCountrySelectorError: 'Land wählen',
        phoneNumberLabel: 'Telefonnummer',
        phoneExample: 'Beispiel:',
        booleanYesLabel: "Ja",
        booleanNoLabel: "Nein",
        questionStepAudioQuestionLabel: "Audiofrage",
        invalidPhoneNumber: "{phone} ist ungültig.",
        invalidEmail: "{email} ist ungültig.",
        questionIsRequired: "Diese Frage ist erforderlich.",
        answerIsNotValid: "Antwort ist ungültig.",
        unfinishedProbingDialogError: "Bitte den Dialog beenden.",
        cannotResumePartialResponse: "Teilantwort kann nicht fortgesetzt werden.",
        failedToSubmitForm: "Fehler beim Senden. Verbindung überprüfen und erneut versuchen.",

        // language picker
        searchLanguage: "Sprache suchen",
    }
}
